input {
	height: 50px;
	width: 100%;
	max-width: 1100px;
	border-radius: 10px;
	padding: 10px;
	color: #000000;
}

textarea {
	height: 225px;
	width: 90vw;
	max-width: 1100px;
	border-radius: 10px;
	padding: 10px;
	resize: none;
	color: #000000;
}

.button {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background: transparent;
    text-decoration: none;
    color: #000000;
    border: 4px solid #000000;
    border-radius: 18px;
    padding: 8px 24px 8px 24px;
    cursor: pointer;
    width: 150px;
    transition: all 0.2s ease-in-out;
}

.button:hover {
		transition: all 0.2s ease-in-out;
		background: #f0f0f0;
	}