.slide {
    width: 100%;
    height: 100%;
}

.slide-current {
    transform: translateX(0%);
    transition: 1s;
}

.slide-prev {
    transform: translateX(-100%);
    transition: 1s;
}

.slide-next{
    transform: translateX(100%);
    transition: 1s;
}

.slide-hidden {
    display: none;
}

.bg-fit {
    object-fit: cover;
}

.bg-free {
    object-fit: contain;
}

.full-fit {
    object-fit: cover;
}

.pic-fit {
    padding-left: 0;
}

@media screen and (min-height: 900px) {
    .full-fit {
        object-fit: cover;
    }
}

@media screen and (max-height: 865px) and (min-width: 481px) {
    /* .full-fit {
        padding-top: 50px;
    } */
    .pic-fit {
        height: 95vh;
        padding: 0;
        padding-left: 200px;
    }
}

@media screen and (max-width: 480px) {
    .full-fit {
        object-fit: cover;
    }
}

.no-grad {
    mask-image: linear-gradient(to right, rgba(240, 240, 240, 0), rgba(240, 240, 240, 1) 50%);
    -webkit-mask-image: linear-gradient(to right, rgba(240, 240, 240, 0), rgba(240, 240, 240, 1) 50%);
}

.grad-full {
    mask-image: linear-gradient(to right, rgba(240, 240, 240, .2), rgba(240, 240, 240, 1) 70%);
    -webkit-mask-image: linear-gradient(to right, rgba(240, 240, 240, .2), rgba(240, 240, 240, 1) 70%);

}

.arrow {
    position: absolute;
    width: 2rem;
    height: 0.8rem;
    color: #000000;
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicator {
    background-color: #000000;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 100%;
    border: none;
    outline: none;
    margin: 0 0.2rem;
    cursor: pointer;
  }
  
  .indicator-inactive {
    background-color: #000000;
    height: 0.5rem;
    width: 0.5rem;
  }